import { Controller } from "@hotwired/stimulus"
import { Notyf } from 'notyf';

export default class extends Controller {
  static values = { object: String, url: String, accion: String, adicional: String }

  confirm(event) {
    console.log(this.adicionalValue)
    Swal.fire({
      title: this.accionValue,
      text: 'Va a ' + this.accionValue.toLowerCase() + ' "' + this.objectValue + '". Está seguro?',
      footer: this.adicionalValue,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Si, ' + this.accionValue.toLowerCase(),
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        const tk = document.querySelector('meta[name="csrf-token"]')
        const token = tk ? encodeURIComponent(tk.getAttribute('content')) : ''
        let method;
        switch (this.accionValue) {
          case 'Eliminar':
            method = 'DELETE';
            break;
          case 'Devolver':
            method = 'POST';
            break;
          //default actualmente es Activar/Desactivar
          default:
            method = 'PUT'; // valor por defecto o un método alternativo
            break;
        }
          fetch(this.urlValue, {
            method: method,
            headers: {
              'X-Csrf-Token': token,
              "Accept": "text/vnd.turbo-stream.html"
            }
          }).then(r => r.text()).then(html => {
            // Toast.fire({
            //   icon: "success",
            //   title: this.objectValue + ' eliminado correctamente.'
            // });
            if (this.accionValue == 'Eliminar') {
              //TODO: aca esto no esta funcionando, redirige y entonces se pierde el notyf (no se ve el cartel eliminado correctamente)
              if (this.urlValue.includes("ordenes_trabajo")) {
                window.location.href = "/ordenes_trabajo";
              } else if (this.urlValue.includes("prestamos")) {
                window.location.href = "/prestamos";
              } else {
                Turbo.renderStreamMessage(html)
              }
              new Notyf().error( this.objectValue + ' eliminado correctamente.')
            } else {
              window.location.reload();
            }
          }
        )
      }
    })
  }
}

