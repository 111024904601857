import { Controller } from "@hotwired/stimulus"
import SlimSelect from 'slim-select'

export default class extends Controller {
  static targets = ['select']
  static values = {
    limit: Number,
    placeholder: String,
    searchText: String,
    searchingText: String,
    reflex: String,
    showSearch: Boolean,
    searchPlaceholder: String,
    ruta: String,
    update: String,
    metodo: String
  }

  connect () {
    super.connect()
    const closeOnSelect = true
    const allowDeselect = true
    const path = this.rutaValue
    this.form = this.element.closest('form')
    this.select = new SlimSelect({
      select: this.selectTarget,
      closeOnSelect: true,
      allowDeselect,
      limit: this.hasLimitValue ? this.limitValue : 999,
      showSearch: this.hasShowSearchValue ? this.showSearchValue : true,
      placeholder: this.hasPlaceholderValue
        ? this.placeholderValue
        : 'Seleccionar',
      searchText: this.hasSearchTextValue ? this.searchTextValue : 'No hay resultados',
      searchPlaceholder: this.hasSearchPlaceholderValue ? this.searchPlaceholderValue : 'Buscar',
      searchingText: this.hasSearchingTextValue
        ? this.searchingTextValue
        : 'Buscando...',
      onChange: this.onChange,
      ajax: function (search, callback) {
        if (search.length < 2) {
          callback('Se necesitan 2 caracteres')
          return
        }
        console.log(path + search)
        fetch(path + search)
        .then(function (response) {
          return response.json()
        })
        .then(function (json) {
          let data = []
          for (let i = 0; i < json.length; i++) {
            data.push({text: json[i].template ? json[i].template : json[i].to_s, value: json[i].id})
          }
          callback(data)
        })
        .catch(function(error) {
          console.log(error)
          callback(false)
        })
      }
    })
  }

  onChange = () => {
    if (this.hasUpdateValue) {
      const tk = document.querySelector('meta[name="csrf-token"]')
      const token = tk ? encodeURIComponent(tk.getAttribute('content')) : ''
      fetch(this.updateValue, {
        method: this.hasMetodoValue ? this.metodoValue : 'POST',
        headers: {
          'X-Csrf-Token': token,
          "Accept": "text/vnd.turbo-stream.html"
        },
        body: new FormData(this.form)
      }).then(r => r.text()).then(html => Turbo.renderStreamMessage(html))
    }
  }

  get single () {
    return !this.selectTarget.multiple
  }
  get multi () {
    return this.selectTarget.multiple
  }

  reconnect () {
    if (this.select) { this.select.destroy() }
    this.connect()
  }
  disconnect() {
    if (this.select) {
      this.select.destroy()
    }
  }
}
